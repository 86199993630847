import React, { useEffect, useState } from "react";
import contractAbi from "../ABI/ContractABI.json";
import { Multicall } from "ethereum-multicall";
import config from "../config/index.js";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getFormatMulticall,
  getFormatMulticall1,
  getFormatMulticall2,
} from "../contractAction/Action.js";
import { bignumber } from "../contractAction/Action.js";
import { validPositive } from "../helper/formatAddr.js";
import Web3 from "web3";
import { Tooltip } from "react-tooltip";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  // display: "block",
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};
let single_digit = [
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
];

function Treeview(props) {
  const walletConnection = useSelector((state) => state?.walletConnection);
  const [loader, setLoader] = useState(false);
  const [Treedata, setTreedata] = useState({});
  const [searchload, setsearchload] = useState(false);
  const [searchid, setsearchid] = useState("");
  const [backload, setbackload] = useState(false);
  const [isvalid, setisvalid] = useState(false);
  const [backarray, setbackarray] = useState([]);

  useEffect(() => {
    if (walletConnection.address) {
      createGenealogyTree(walletConnection.address);
    }
  }, [walletConnection?.address]);

  useEffect(() => {
    getsearchdata();
  }, [searchid]);

  const getsearchdata = async () => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection?.web3);
        setsearchload(true);
        if (searchid == "") {
          setisvalid(false);
          createGenealogyTree(walletConnection.address);
          setsearchload(false);

          return false;
        }

        var contractcall = new web3.eth.Contract(contractAbi, config.Contract);

        let getaddress = await contractcall.methods.userList(searchid).call();
        if (getaddress != "0x0000000000000000000000000000000000000000") {
          setisvalid(false);
          await createGenealogyTree(getaddress);
          setsearchload(false);
        } else {
          setsearchload(false);
          setisvalid(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clickBack = async () => {
    try {
      setbackload(true);
      let lastId = backarray[backarray.length - 2];
      lastId = lastId == undefined ? walletConnection?.userdetails?.id : lastId;
      await getClickTree(lastId, "back");
      backarray.pop();
      setbackload(false);
    } catch (err) {
      setbackload(false);
      console.log(err);
    }
  };

  const getClickTree = async (userid, type) => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection?.web3);

        var contractcall = new web3.eth.Contract(contractAbi, config.Contract);
        setLoader(true);
        let getaddress = await contractcall.methods.userList(userid).call();
        if (getaddress != "0x0000000000000000000000000000000000000000") {
          if (type != "back") {
            setbackarray((backarray) => [...backarray, userid]);
          }
          setisvalid(false);
          await createGenealogyTree(getaddress);
          setLoader(false);
        } else {
          setisvalid(true);
          setLoader(false);
        }
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  async function createGenealogyTree(currentUser) {
    if (
      currentUser === "0x0000000000000000000000000000000000000000" ||
      !walletConnection.address
    ) {
      return null;
    }

    const web3 = new Web3(walletConnection?.web3);
    var contractcall = new web3.eth.Contract(contractAbi, config.Contract);
    let userdetails = await contractcall.methods.userinfo(currentUser).call();

    let firstuser = {
      id: userdetails.id,
      right: {},
      left: {},
      rightAddress: userdetails.right,
      leftAddress: userdetails.left,
      parent: userdetails.parent,
      currentplan: userdetails.userCurrentPlan,
    };
    let web3call = new Web3(
      walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
        ? walletConnection.provider
        : window.ethereum
    );
    const multicall = new Multicall({
      web3Instance: web3call
    });

    const addresses = [firstuser?.leftAddress, firstuser?.rightAddress];

    const contractData = addresses.map((address, index) => ({
      reference: `details-${index}`,
      contractAddress: config.Contract,
      abi: contractAbi,
      calls: [
        {
          reference: "userinfo",
          methodName: "userinfo",
          methodParameters: [address],
        },
      ],
    }));
    try {
      const results = await multicall.call(contractData);
      let firsttwo = [];
      for (let i = 0; i < addresses.length; i++) {
        let userprofile = await getFormatMulticall1(results, "details-" + i, 0);
        firsttwo.push(userprofile);
      }

      let secondfour = [];
      for (const element of firsttwo) {
        let userID = await bignumber(element[1]);
        let right = element[5];
        let left = element[6];
        let parent = element[2];

        let userCurrentPlan = await bignumber(element[10]);

        var data2 = {
          id: userID == 0 ? undefined : userID,
          right: {},
          left: {},
          rightAddress: right,
          leftAddress: left,
          parent: parent,
          currentplan: userID == 0 ? undefined : userCurrentPlan,
        };

        secondfour.push(data2);
      }
      firstuser.left = secondfour[0];
      firstuser.right = secondfour[1];

      await secondTree(secondfour, firstuser); // Assuming secondTree is a function to handle the data
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
  }
  const secondTree = async (secondArr, joindata) => {
    try {
      const web3 = new Web3(walletConnection?.web3);
      let web3call = new Web3(
        walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );
      const multicall = new Multicall({ web3Instance: web3call });

      const contractData = secondArr.map((address, index) => ({
        reference: `details-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: "userinfo",
            methodName: "userinfo",
            methodParameters: [address.rightAddress],
          },
          {
            reference: "userinfo",
            methodName: "userinfo",
            methodParameters: [address.leftAddress],
          },
        ],
      }));

      let thirdeight = [];
      for (let i = 0; i < secondArr.length; i++) {
        const results = await multicall.call(contractData);
        let firsttwo = [];
        let userprofile = await getFormatMulticall2(results, "details-" + i);

        firsttwo.push(userprofile);

        for (const element of userprofile) {
          let userID = await bignumber(element.returnValues[1]);

          let reghtAddress = element.returnValues[5];
          let leftAddress = element.returnValues[6];
          let parent = element.returnValues[2];
          let userCurrentPlan = await bignumber(element.returnValues[10]);

          var data2 = {
            id: userID == 0 ? undefined : userID,
            right: {},
            left: {},
            parent: parent,
            rightAddress: reghtAddress,
            leftAddress: leftAddress,
            currentplan: userID == 0 ? undefined : userCurrentPlan,
          };
          thirdeight.push(data2);
        }
      }

      joindata.left.left = await thirdeight[1];
      joindata.left.right = await thirdeight[0];

      joindata.right.left = await thirdeight[3];
      joindata.right.right = await thirdeight[2];

      await getEightData(thirdeight, joindata);
    } catch (err) {
      console.error("Error in secondTree:", err);
    }
  };
  const getEightData = async (eightdata, joindata) => {
    try {
      const web3 = new Web3(walletConnection?.web3);
      let web3call = new Web3(
        walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );
      const multicall = new Multicall({ web3Instance: web3call });

      const contractData = eightdata.map((address, index) => ({
        reference: `details-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: "userinfo",
            methodName: "userinfo",
            methodParameters: [address.rightAddress],
          },
          {
            reference: "userinfo",
            methodName: "userinfo",
            methodParameters: [address.leftAddress],
          },
        ],
      }));

      let thirdeight = [];
      for (let i = 0; i < eightdata.length; i++) {
        const results = await multicall.call(contractData);

        let firsttwo = [];
        let userprofile = await getFormatMulticall2(results, "details-" + i);
        firsttwo.push(userprofile);

        for (const element of userprofile) {
          let userID = await bignumber(element.returnValues[1]);
          let reghtAddress = element.returnValues[5];
          let leftAddress = element.returnValues[6];
          let parent = element.returnValues[2];
          let userCurrentPlan = await bignumber(element.returnValues[10]);

          var data2 = {
            id: userID == 0 ? undefined : userID,
            right: {},
            reghtAddress: reghtAddress,
            leftAddress: leftAddress,
            currentplan: userID == 0 ? undefined : userCurrentPlan,
          };
          thirdeight.push(data2);
        }
      }

      joindata.left.left.left = await thirdeight[3];
      joindata.left.left.right = await thirdeight[2];

      joindata.left.right.left = await thirdeight[1];
      joindata.left.right.right = await thirdeight[0];

      joindata.right.left.left = await thirdeight[7];
      joindata.right.left.right = await thirdeight[6];

      joindata.right.right.left = await thirdeight[5];
      joindata.right.right.right = await thirdeight[4];

      setTreedata(joindata);

      return true;
    } catch (err) {
      console.error("Error in secondTree:", err);
    }
  };

  return (
    <>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#dash_accordion"
      >
        <div class="accordion-body">
          <div className="tree_search">
            <input
              type="text"
              placeholder="Search ID"
              className="form-control primary_input"
              onChange={(e) => setsearchid(e.target.value)}
              onInput={validPositive}
            />
            {searchload && (
              <i
                class="fa fa-spinner fa-spin checkspin"
                style={{ fontSize: "24px" }}
              ></i>
            )}
          </div>
          <Tooltip id="my-tooltip" className="custom_tooltip" />
          <div class="tree">
            {isvalid ? (
              <p style={{ color: "#f9f9f9" }}>UserID Not Found</p>
            ) : (
              <ul>
                <li>
                  <span
                    className={`tree_package_${single_digit[Treedata.currentplan]
                      }`}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={`TETRA ${Treedata.id || "No User"}`}
                  >
                    <img
                      src={require("../assets/images/tree_icon.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                  </span>
                  <ul>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        className={`tree_package_${single_digit[Treedata?.left?.currentplan]
                          }`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`TETRA ${Treedata.left?.id || "No User"
                          }`}
                        onClick={() => getClickTree(Treedata.left?.id)}
                      >
                        <img
                          src={require("../assets/images/tree_icon.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                      </span>
                      <ul>
                        <li>
                          <span
                            style={{ cursor: "pointer" }}
                            className={`tree_package_${single_digit[Treedata?.left?.left?.currentplan]
                              }`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`TETRA ${Treedata.left?.left?.id || "No User"
                              }`}
                            onClick={() =>
                              getClickTree(Treedata.left?.left?.id)
                            }
                          >
                            <img
                              src={require("../assets/images/tree_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </span>
                          <ul className="mobile_hide">
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.left?.left?.left?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.left?.left?.left?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.left?.left?.left?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.left?.left?.right?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.left?.left?.right?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.left?.left?.right?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <span
                            style={{ cursor: "pointer" }}
                            className={`tree_package_${single_digit[Treedata?.left?.right?.currentplan]
                              }`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`TETRA ${Treedata.left?.right?.id || "No User"
                              }`}
                            onClick={() =>
                              getClickTree(Treedata.left?.right?.id)
                            }
                          >
                            <img
                              src={require("../assets/images/tree_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </span>
                          <ul className="mobile_hide">
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.left?.right?.left?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.left?.right?.left?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.left?.right?.left?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.left?.right?.right?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.left?.right?.right?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.left?.right?.right?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        className={`tree_package_${single_digit[Treedata?.right?.currentplan]
                          }`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`TETRA ${Treedata.right?.id || "No User"
                          }`}
                        onClick={() => getClickTree(Treedata.right?.id)}
                      >
                        <img
                          src={require("../assets/images/tree_icon.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                      </span>
                      <ul>
                        <li>
                          <span
                            style={{ cursor: "pointer" }}
                            className={`tree_package_${single_digit[Treedata?.right?.left?.currentplan]
                              }`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`TETRA ${Treedata.right?.left?.id || "No User"
                              }`}
                            onClick={() =>
                              getClickTree(Treedata.right?.left?.id)
                            }
                          >
                            <img
                              src={require("../assets/images/tree_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </span>
                          <ul className="mobile_hide">
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.right?.left?.left?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.right?.left?.left?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.right?.left?.left?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.right?.left?.right?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.right?.left?.right?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.right?.left?.right?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <span
                            style={{ cursor: "pointer" }}
                            className={`tree_package_${single_digit[Treedata?.right?.right?.currentplan]
                              }`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`TETRA ${Treedata.right?.right?.id || "No User"
                              }`}
                            onClick={() =>
                              getClickTree(Treedata.right?.right?.id)
                            }
                          >
                            <img
                              src={require("../assets/images/tree_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </span>
                          <ul className="mobile_hide">
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.right?.right?.left?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.right?.right?.left?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.right?.right?.left?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ cursor: "pointer" }}
                                className={`tree_package_${single_digit[
                                  Treedata?.right?.right?.right?.currentplan
                                ]
                                  }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`TETRA ${Treedata.right?.right?.right?.id || "No User"
                                  }`}
                                onClick={() =>
                                  getClickTree(Treedata.right?.right?.right?.id)
                                }
                              >
                                <img
                                  src={require("../assets/images/tree_icon.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
          <button className="primary_btn mt-3" onClick={clickBack}>
            {backload && (
              <i class="fa fa-spinner fa-spin" style={{ fontSize: "14px" }}></i>
            )}
            Back
          </button>
        </div>
      </div>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#72d438"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}

export default Treeview;
