let key = {};

let toasterOption = {
    position: "top-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
};
let Envname = "prodction";


if (Envname === "prodction") {

    key = {
        frontend: "https://www.tetravision.io",
        NetworkId: 56,
        frontUrl: "https://www.tetravision.io/",

        Contract: "0x2be851eeDf2aF64Cbd769FBf4dD444396a74f626",
        TokenContract: "0x55d398326f99059fF775485246999027B3197955",
        defalutAddr: "0xeF2FD979be3E61f21E284f0d4CBFe0Cb2315cA7A",

        rpcUrl: "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
        bscscan: "https://bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://bscscan.com/tx/"
    };


} else if (Envname === "demo") {

    key = {
        frontend: "https://tetravision-frontend.pages.dev",
        NetworkId: 97,
        frontUrl: "https://tetravision-frontend.pages.dev/",
        Contract: "0xd56087E3BfFF8164893B1be764304ab4A6f26A44",
        TokenContract: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
        rpcUrl: "https://bsc-testnet.publicnode.com",
        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,

        txURl: "https://testnet.bscscan.com/tx/"
    };

} else {
    key = {
        frontend: "http://localhost:3000",
        NetworkId: 97,
        frontUrl: "http://localhost:3000/",
        Contract: "0xD0546c5A6DF37656fdE695A264A43C2b60dcbC2C",
        TokenContract: "0x65b7E301040752CDeD08Acb441aF5AA027f91aE3",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
        rpcUrl: "https://bsc-testnet.publicnode.com",
        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,

        txURl: "https://testnet.bscscan.com/tx/"

    };

    console.log(key, "key");
}

export default key;
