
import { Multicall } from "ethereum-multicall";
import BigNumber from "bignumber.js"
//config
import config from "../config/index"
import { connection } from "../helper/connection"
import contractAbi from "../ABI/ContractABI.json"


export function getFormatMulticall(results, name, pos) {
    try {
        var index =
            results &&
            results.results &&
            results.results.details &&
            results.results.details.callsReturnContext &&
            results.results.details.callsReturnContext.findIndex(
                (val) => val.reference == name
            );
        var returnVal =
            results.results.details.callsReturnContext[index] &&
                results.results.details.callsReturnContext[index].returnValues
                ? results.results.details.callsReturnContext[index].returnValues
                : "";
        return returnVal;
    } catch (err) {
        console.log(err, "==+++++++==");
        return "";
    }
}

export async function getFormatMulticall1(results, name, pos) {
    try {
        var returnVal =
            results &&
                results.results &&
                results.results[name] &&
                results.results[name].callsReturnContext &&
                results.results[name].callsReturnContext &&
                results.results[name].callsReturnContext[pos] &&
                results.results[name].callsReturnContext[pos].returnValues &&
                results.results[name].callsReturnContext[pos].returnValues
                ? results.results[name].callsReturnContext[pos].returnValues
                : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function getFormatMulticall2(results, name) {
    try {
        var returnVal =
            results &&
                results.results &&
                results.results[name] &&
                results.results[name].callsReturnContext &&
                results.results[name].callsReturnContext
                ? results.results[name].callsReturnContext
                : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
}

export const UpgradePlan = () => {
    try {



    } catch (err) {
        console.log(err);
    }
}

