import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import {
  bsc,
  bscTestnet,
  polygon,
  polygonMumbai,
  sepolia,
  mainnet
} from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import DashboardDetail from "./pages/dashboard-detail.js";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()]
);


// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true
    }),
    new WalletConnectConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2"
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});


const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/join-now/:id", element: <Home /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "dashboard-detail/:id", element: <DashboardDetail /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <WagmiConfig config={config}>
        <ToastContainer />
        <Router basename="/">
          <App />
        </Router>
      </WagmiConfig>
    </Provider>
  );
};

export default AppWrapper;