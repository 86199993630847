import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//lib
import PropagateLoader from "react-spinners/PropagateLoader";
import StrategyChart from "../components/Dashboard/StrategyChart.js";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert.js";

//config
import config from "../config/index.js";

//ABI
import contractAbi from "../ABI/ContractABI.json";
import TokenABI from "../ABI/TokenABI.json";

//component
import Treeview from "../components/Treeview.js";

//basic component
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};
let single_digit = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

export default function DashboardDetail(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [userpool, setuserpool] = useState([])
  const [poolinfo, setpoolinfo] = useState([]);



  useEffect(() => {
    getPlanData()
    getpooldetail()
  }, [walletConnection.address, id])

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);


  //get pool info
  const getpooldetail = async () => {
    setLoader(true)
    if (walletConnection.address) {

      var web3 = new Web3(walletConnection.web3);
      var contractcall = new web3.eth.Contract(
        contractAbi,
        config.Contract
      );
      let poollength = await contractcall.methods.getPoolLenght().call();
      const poolarr = []
      let isdisablethree = false
      let isdisablesix = false

      for (let i = 0; i < poollength; i++) {

        let pooldata = await contractcall.methods.poolInfo(i).call();
        let poolinfodata = await contractcall.methods.userpool(walletConnection.address, i).call();
        poolarr.push({
          poolNo: i, cappingAmount: Number(pooldata.cappingAmount / 1e18), poolAmount: Number(pooldata.poolAmount / 1e18), isexits: poolinfodata.cappingAmount / 1e18 > 0, totalinc: poolinfodata.totInc / 1e18, isdisablethree: isdisablethree, isdisablesix: isdisablesix
        })
        if (i == 2) {
          if (walletConnection.userdetails?.totalInc / 1e18 <= pooldata.cappingAmount / 1e18) {
            isdisablethree = true
          }
        }
        if (i == 5) {
          if (walletConnection.userdetails?.totalInc / 1e18 <= pooldata.cappingAmount / 1e18) {
            isdisablethree = true
          }
        }
      }
      setLoader(false)
      setpoolinfo(poolarr)
    }
  }

  //get particular plan data 
  const getPlanData = async () => {
    try {
      // setLoader(true)
      if (walletConnection.address) {

        if (!walletConnection?.userdetails?.isExist) {
          window.location.href = "/"
        }

        var web3 = new Web3(walletConnection.web3);
        var contractcall = new web3.eth.Contract(
          contractAbi,
          config.Contract
        );

        let poolUserData = await contractcall.methods.userpool(walletConnection.address, id).call();

        console.log(poolUserData, "poolUserData");
        setuserpool(poolUserData)
        setLoader(false)
      }
    } catch (err) {
      console.log(err);

    }
  }

  // upgrade plan
  const UpgradePlan = async (poolid) => {
    try {
      if (walletConnection.address) {
        setLoader(true)
        var web3 = new Web3(walletConnection?.web3);
        const ContractToken = new web3.eth.Contract(
          TokenABI,
          config.TokenContract
        );
        var contractcall = new web3.eth.Contract(
          contractAbi,
          config.Contract
        );

        let firstPlan = await contractcall.methods.poolInfo(poolid).call();
        let planAmount = Number(firstPlan.poolAmount) / config.decimalVal;

        let tokenBal = await ContractToken.methods
          .balanceOf(walletConnection.address)
          .call();

        if (Number(planAmount) > Number(tokenBal / 1e18)) {
          toastAlert("error", "Your USDT balance is low");
          setLoader(false)
          return false;
        }

        let estimateGas = await ContractToken.methods
          .approve(config.Contract, (planAmount * 1e18).toString())
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));

        let gasPriceUsdt = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;


        var balance = await web3.eth.getBalance(walletConnection.address);

        if (estimateGasFee > balance / 1e18) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false)
          return false;
        }

        let currentplan = walletConnection.userdetails?.userCurrentPlan
        if (currentplan != poolid - 1) {
          toastAlert("error", `Please Upgrade Package ${Number(currentplan) + 2} `);
          setLoader(false)
          return false;
        }

        setLoader(true)
        await ContractToken.methods.approve(config.Contract, (planAmount * 1e18).toString()).send({ from: walletConnection.address }).then(async (res) => {
          await contractcall.methods.reJoinPlan(poolid).send({ from: walletConnection.address }).then(async (res) => {
            toastAlert("success", "Upgrade Successfully");
            setTimeout(() => {
              window.location.reload()
            }, 1500);
            setLoader(false)
          })
        })
      }
    } catch (err) {
      console.log(err);
      toastAlert("error", "Transaction Rejected");
      setLoader(false)

    }
  }



  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="flex_maintitle">
                <Link to="/dashboard">
                  <img
                    src={require("../assets/images/menu_prev_icon.png")}
                    alt="Logo"
                  />
                </Link>
                <h2 className="inner_head_title">
                  MY ACCOUNT DETAILS
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center dash_height_row dash_height_row_inner">

            <div className="col-lg-12">
              <div className="dash_top_box dash_top_box_package_one">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="user_info_panel">
                      <img
                        src={require("../assets/images/logo_icon.png")}
                        alt="Logo"
                        className="img-fluid"
                      />
                      <div className="user_info">
                        <h4>Package {Number(id) + 1}</h4>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="user_earnings_panel">
                      {userpool != "" &&
                        <StrategyChart
                          totalinc={userpool?.totInc / 1e18 || 0}
                          totalclap={userpool?.cappingAmount / 1e18 || 0}
                        />
                      }
                      <div className="user_earnings_info">
                        <h4>Total Capping Amount</h4>
                        <h3>
                          {userpool?.totInc / 1e18 || 0}<span>/{userpool?.cappingAmount / 1e18 || 0}</span> <small>USDT</small>{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="accordion dashboard_accordian" id="dash_accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                      >
                        <path
                          d="M12.8106 21.6406H9.59387M12.8106 21.6406L12.8105 20.8937C12.8105 18.5206 14.7342 16.5969 17.1073 16.5969M12.8106 21.6406H21.4043V20.8937C21.4043 18.5206 19.4804 16.5969 17.1073 16.5969M9.59387 21.6406L9.59375 20.8937C9.59375 18.5206 7.67 16.5968 5.29687 16.5968C2.92375 16.5968 1 18.5206 1 20.8937V21.6406H9.59387ZM6.8855 10.1081C7.96324 8.95505 9.49774 8.23429 11.2006 8.23429C12.9043 8.23429 14.4396 8.95578 15.5174 10.1099M17.1073 16.5969C19.0058 16.5969 20.5448 15.0579 20.5448 13.1594C20.5448 11.2609 19.0058 9.72189 17.1073 9.72189C15.2088 9.72189 13.6698 11.2609 13.6698 13.1594C13.6698 15.0579 15.2088 16.5969 17.1073 16.5969ZM14.647 4.79687C14.647 6.69536 13.108 8.23437 11.2095 8.23437C9.31098 8.23437 7.77197 6.69536 7.77197 4.79687C7.77197 2.89839 9.31098 1.35938 11.2095 1.35938C13.108 1.35938 14.647 2.89839 14.647 4.79687ZM8.75595 13.1816C8.75595 15.0924 7.20692 16.6414 5.2961 16.6414C3.38533 16.6414 1.8363 15.0924 1.8363 13.1816C1.8363 11.2708 3.38533 9.72177 5.2961 9.72177C7.20692 9.72177 8.75595 11.2708 8.75595 13.1816Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      My Team
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingFour"
                    data-bs-parent="#dash_accordion"
                  >
                    <div class="accordion-body">
                      <div className="dash_top_box">
                        <div className="row mt-4">
                          <div className="col-lg-6 col-md-6">
                            <div className="dash_black_box">
                              <div>
                                <h4>My Matrix Team</h4>
                                <h5>{Number(userpool?.matCount1) + Number(userpool?.matCount2) || 0}</h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="dash_black_box">
                              <div>
                                <h4>My Passive Team</h4>
                                <h5>{userpool?.passCount || 0}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="27"
                        viewBox="0 0 18 27"
                        fill="none"
                      >
                        <path
                          d="M12.8099 7.62435H5.54355M7.45056 20.4943C7.70582 21.0708 8.30344 21.4753 8.99999 21.4753C9.92867 21.4753 10.6815 20.7561 10.6815 19.869C10.6815 18.9819 9.92867 18.2628 8.99999 18.2628C8.07126 18.2628 7.3184 17.5436 7.3184 16.6565C7.3184 15.7695 8.07126 15.0503 8.99999 15.0503C9.4548 15.0503 9.86742 15.2228 10.1701 15.503M8.99995 21.582V22.1975M8.99995 14.2878V14.8751M15.0382 8.88491L12.8981 7.64694L15.4382 7.3329M14.4214 13.209C12.9675 11.967 11.1108 9.86491 11.5659 7.76848C11.6492 7.38451 12.1308 6.42549 12.7983 5.77829C13.5416 5.0577 13.7803 4.02103 13.4066 3.13711C13.0647 2.32813 12.2838 1.8313 11.318 1.80796C11.3117 1.80785 11.3053 1.80775 11.2989 1.80775C11.1369 1.80775 10.9771 1.82378 10.8215 1.85521C10.4325 1.27943 9.71447 0.994782 9 1.00007C8.29579 1.00531 7.59506 1.29229 7.21633 1.86107C7.04398 1.82269 6.86566 1.80142 6.68479 1.80375C5.72897 1.8107 4.92469 2.32943 4.5857 3.15754C4.21597 4.06091 4.47509 5.12222 5.2305 5.79846C5.79483 6.30365 6.34673 7.30894 6.4481 7.76848C6.91003 9.86299 5.03611 11.9653 3.57856 13.209C2.2037 14.47 1 16.2488 1 18.4026C1.42614 28.537 16.5784 28.5279 17 18.4026C17 16.2488 15.7962 14.47 14.4214 13.209Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      My Income
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#dash_accordion"
                  >
                    <div class="accordion-body">
                      <div className="dash_top_box">
                        <div className="dash_black_box_grid">
                          <div className="dash_black_box_grid_single">
                            <div className="dash_black_box">
                              <div>
                                <h4>My Total Earning</h4>
                                <h5>
                                  {userpool?.totInc / 1e18 || 0} <span>USDT</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="dash_black_box_grid_single">
                            <div className="dash_black_box">
                              <div>
                                <h4>Matrix Income</h4>
                                <h5>
                                  {(Number(userpool?.thiLevInc) + Number(userpool?.secLevInc)) / 1e18 || 0} <span>USDT</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="dash_black_box_grid_single">
                            <div className="dash_black_box">
                              <div>
                                <h4>Direct Income</h4>
                                <h5>
                                  {userpool?.dirInc / 1e18 || 0} <span>USDT</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="dash_black_box_grid_single">
                            <div className="dash_black_box">
                              <div>
                                <h4>Passive Income</h4>
                                <h5>
                                  {userpool?.pasInc / 1e18 || 0} <span>USDT</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="dash_black_box_grid_single">
                            <div className="dash_black_box">
                              <div>
                                <h4>Royalty Income</h4>
                                <h5>
                                  {userpool.royInc / 1e18 || 0} <span>USDT</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="29"
                        viewBox="0 0 26 29"
                        fill="none"
                      >
                        <path
                          d="M5.14938 12.9036C5.14938 10.612 3.29165 8.75427 1 8.75427M5.14938 4.5304C5.14938 2.8403 3.7793 1.47023 2.08921 1.47023M19.8506 12.9036C19.8506 10.612 21.7084 8.75427 24 8.75427M19.8506 4.53038C19.8506 2.84029 21.2207 1.47021 22.9108 1.47021M18.0819 15.6007V27.5302M18.0819 15.6007H3.282L1.16499 19.387H15.9649L18.0819 15.6007ZM18.0819 15.6007H22.2832L24.4002 19.387H20.1989L18.0819 15.6007ZM18.0819 27.5302H3.26561V19.4389M18.0819 27.5302H22.2832V19.4908M14.7794 8.52087L12.7825 6.52398L10.7857 8.52087M10.3261 12.4638H15.0315M17.8025 7.65385C17.8025 4.82413 15.5086 2.5302 12.6788 2.5302C9.84913 2.5302 7.55519 4.82413 7.55519 7.65385C7.55519 9.63539 8.68014 11.354 10.3261 12.2063V15.6007H15.0315V12.2063C16.6775 11.354 17.8025 9.63539 17.8025 7.65385Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Subscription Packages
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#dash_accordion"
                  >
                    <div class="accordion-body">
                      <div className="packages_panel">
                        {poolinfo && poolinfo.map((item, index) => {
                          if (item.poolNo != id)
                            return (
                              <div className={`packages_div packages_div_${single_digit[index]} ${item.isexits ? "active_package" : ""} ${item.isdisablethree ? "package_diasbled" : ""}`}>
                                <div className="packages_div_inner">
                                  <div className="package_details">
                                    <h3>Package {item.poolNo + 1}</h3>
                                    <h4>
                                      {item.poolAmount} <span>USDT</span>
                                    </h4>
                                  </div>
                                  {item.isexits ?
                                    <>
                                      <div class="progress">
                                        <div
                                          class="progress-bar progress-bar-striped progress-bar-animated"
                                          role="progressbar"
                                          aria-valuenow="50"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          style={{ width: `${Math.floor((item.totalinc / item.cappingAmount) * 100)}%` }}
                                        ></div>
                                      </div>
                                      <div className="progress_info">
                                        <p>Capping Percentage {Math.floor((item.totalinc / item.cappingAmount) * 100)}%</p>
                                        <p>
                                          {item.totalinc} <span>/{item.poolNo == 9 ? "No capping" : Math.round(item.cappingAmount)} USDT</span>                                        </p>
                                      </div>

                                      <a href={`/dashboard-detail/${item.poolNo}`} class="primary_btn">
                                        <span class="text">Team & Income Details</span>
                                      </a>
                                    </>
                                    :
                                    <>
                                      <div className="capping_div">
                                        <h3>Capping</h3>
                                        {item.poolNo == 9 ? <h4>
                                        <span>No capping</span>
                                      </h4> :
                                        <h4>
                                          {Math.round(item.cappingAmount)} <span>USDT</span>
                                        </h4>}
                                      </div>
                                      <button class="primary_btn" onClick={() => UpgradePlan(item.poolNo)}
                                        disabled={loader}>
                                        <span class="text">Upgrade</span>
                                      </button>
                                    </>}
                                </div>
                              </div>
                            )
                        })}


                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                      >
                        <path
                          d="M13.5 6.18672C14.9322 6.18672 16.0933 5.02562 16.0933 3.59336C16.0933 2.1611 14.9322 1 13.5 1C12.0677 1 10.9066 2.1611 10.9066 3.59336C10.9066 5.02562 12.0677 6.18672 13.5 6.18672ZM13.5 6.18672C11.2083 6.18672 9.3506 8.04444 9.3506 10.3361V11.8921H17.6494V10.3361C17.6494 8.04444 15.7916 6.18672 13.5 6.18672ZM5.14938 20.2946C6.58164 20.2946 7.74274 19.1335 7.74274 17.7012C7.74274 16.269 6.58164 15.1079 5.14938 15.1079C3.71712 15.1079 2.55602 16.269 2.55602 17.7012C2.55602 19.1335 3.71712 20.2946 5.14938 20.2946ZM5.14938 20.2946C2.85773 20.2946 1 22.1523 1 24.444V26H9.29876V24.444C9.29876 22.1523 7.44103 20.2946 5.14938 20.2946ZM13.5 11.8921V18.1681L18.4226 22.1061M8.57738 22.1061L13.5 18.1681M24.4439 17.7012C24.4439 19.1335 23.2828 20.2946 21.8506 20.2946C20.4183 20.2946 19.2572 19.1335 19.2572 17.7012C19.2572 16.269 20.4183 15.1079 21.8506 15.1079C23.2828 15.1079 24.4439 16.269 24.4439 17.7012ZM26 26H17.7013V24.444C17.7013 22.1523 19.559 20.2946 21.8507 20.2946C24.1423 20.2946 26 22.1523 26 24.444V26Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      My Tree
                    </button>
                  </h2>

                  <Treeview />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {
        loader && (
          <div className="loadBefore">
            <PropagateLoader
              color={"#72d438"}
              loading={loader}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )
      }
    </div>
  );
}
