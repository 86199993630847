import React from "react";
import logo from "../assets/images/logo.svg";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            <div className="footer_about">
              <img src={logo} alt="Logo" className="img-fluid" />
              <p>
              The Best Crypto Based Affiliate Program In The Industry. It Offers 4 Ways Of Earning USDT Directly To Your Wallet And Is So Easy A Beginner Could Do It.
              </p>
            </div>
            {/* <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i class="fa-solid fa-paper-plane"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i class="fa-brands fa-reddit-alien"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
            </ul> */}
          </div>
          <div className="footer_panel_bottom">
            <p>
              Copy right © {new Date().getFullYear()} <strong>Tetravision</strong>,
              All Rights Reserved
            </p>
            <ul className="footer_menu_links">
              {/* <li>
                <a href="#">Terms of service</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li> */}
            </ul>
          </div>          
        </div>
      </div>
    </footer>
  );
}
