import React, { memo } from 'react'
import { useSelector } from "react-redux";
import StrategyChart from "../components/Dashboard/StrategyChart.js";
import ShareLink from "../components/ShareLink.js";
import moment from "moment";

function DashboardDetails({ referraldata }) {
    const walletConnection = useSelector((state) => state?.walletConnection);

    return (
        <div className="dash_top_box">
            <div className="row">
                <div className="col-lg-6">
                    <div className="user_info_panel">
                        <img
                            src={require("../assets/images/logo_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                        />
                        <div className="user_info">
                            <h4>TETRA {walletConnection?.userdetails?.id}</h4>
                            <h5>
                                Joined Date: <span>{moment(Number(walletConnection?.userdetails?.joinedDate * 1000)).format("lll")}</span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="user_earnings_panel">
                        {walletConnection.address &&
                            <StrategyChart totalinc={walletConnection.userdetails?.totalInc / 1e18 || 0}
                                totalclap={walletConnection.userdetails?.cappingAmount / 1e18 || 0}

                            />}
                        <div className="user_earnings_info">
                            <h4>Total Capping Amount</h4>
                            <h3>
                                {walletConnection.userdetails?.totalInc / 1e18 || 0}   <span>/{walletConnection.userdetails?.cappingAmount / 1e18 || 0}</span> <small>USDT</small>{" "}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-3 col-md-6">
                    <div className="dash_black_box">
                        <div>
                            <h4>Total Subscription </h4>
                            <h5>
                                {walletConnection.userdetails?.investAmount / 1e18 || 0} <span>USDT</span>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="dash_black_box">
                        <div>
                            <h4>Total Direct Referrals</h4>
                            <h5>{referraldata?.length || 0}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="dash_black_box">
                        <div>
                            <h4>My Team</h4>
                            <h5>{walletConnection?.userdetails?.teamCount || 0}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <ShareLink />
                </div>
            </div>
        </div>
    )
}

export default memo(DashboardDetails)