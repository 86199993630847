import React, { useEffect, useState } from 'react'
import contractAbi from "../ABI/ContractABI.json"
import Web3 from "web3";
import { useSelector } from "react-redux";
import config from "../config/index.js"
import copy from "copy-to-clipboard";
import { toastAlert } from "../helper/toastAlert.js";


function Legcount() {
    const walletConnection = useSelector((state) => state?.walletConnection);
    const [referraldata, setreferraldata] = useState([]);

    useEffect(() => {
        getlegcount()
    }, [walletConnection?.address])


    //get UserId ---passing address
    const getIdloop = async (addr) => {
        try {
            if (addr === "0x0000000000000000000000000000000000000000" || !walletConnection.address) {
                return null;
            }
            const web3 = new Web3(walletConnection?.web3);
            var contractcall = new web3.eth.Contract(
                contractAbi,
                config.Contract
            );

            let userdetails = await contractcall.methods.userinfo(addr).call();

            return userdetails.id

        } catch (err) {
            console.log(err);

        }

    }

    //get particular user Leg count 
    const getlegcount = async () => {
        try {
            if (walletConnection.address) {
                var web3 = new Web3(walletConnection?.web3);
                var contractcall = new web3.eth.Contract(
                    contractAbi,
                    config.Contract
                );
                let referraldata = await contractcall.methods.viewUserRefaral(walletConnection?.address).call();

                let legdata = []
                // for (let i = 0; i < referraldata.length; i++) {
                //     let referalteam = await contractcall.methods.userteam(referraldata[i]).call();

                //     legdata.push({ address: referraldata[i], id: await getIdloop(referraldata[i]), exist: referalteam.exist, parent: referalteam.parent, teamCount: referalteam.teamCount, teamVolume: referalteam.teamVolume })

                // }
                for (const address of referraldata) {
                    let referalteam = await contractcall.methods.userteam(address).call();
                    legdata.push({
                        address,
                        id: await getIdloop(address),
                        exist: referalteam.exist,
                        parent: referalteam.parent,
                        teamCount: referalteam.teamCount,
                        teamVolume: referalteam.teamVolume
                    });
                }
                setreferraldata(legdata)
            }
        } catch (err) {
            console.log(err);

        }


    }

    // COPY TEXT FUN.
    async function copyToClipboard(clipdata) {
        copy(clipdata)
        toastAlert("success", "Copied Successfully");

    }

    return (
        <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#dash_accordion"
        >
            <div class="accordion-body">
                <div className="table-responsive">
                    <table className="table primary_table">
                        <thead>
                            <tr>
                                <th>My Directs</th>
                                <th>Team Count</th>
                                <th>Team Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            {referraldata && referraldata.map((item) => (
                                <tr>
                                    <td>{item.id}
                                        {""}
                                        <a href="javascript:void(0);" className="text-decoration-none" onClick={() => copyToClipboard(item.id)}>
                                            {" "}
                                            <i class="fa-solid fa-copy ms-1"></i>
                                        </a>
                                    </td>
                                    <td>{item.teamCount}</td>
                                    <td>{item.teamVolume / 1e18 || 0}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default Legcount