import React from "react";
import ReactApexChart from "react-apexcharts";

export default function StrategyChart({ totalinc, totalclap }) {

  let percentage = Math.floor((totalinc / totalclap) * 100) + "%"
  let percentage1 = Math.floor((totalinc / totalclap) * 100)



  let capData = totalinc >= totalclap ? 0 : totalclap - totalinc


  const chartData = {
    series: [totalinc, capData],
    labels: ["Earning", "Remaining"],
  };

  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: chartData.labels,
    stroke: {
      width: "2",
      colors: "#000",
    },
    legend: {
      fontSize: "16px",
      fontFamily: "Montserrat",
      labels: {
        colors: "#9F9F9F",
        useSeriesColors: false,
      },
      markers: {
        width: 8,
        height: 8,
        radius: 10,
        offsetX: -5,
        offsetY: -3,
        fillColors: ["#0056FF", "#FF0008"],
      },
      position: "right",
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },
    fill: {
      colors: ["#0056FF", "#FF0008"],
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "18px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#ffffff",
              offsetY: -10,
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              formatter: () => percentage || 0,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
            markers: {             
              offsetX: -1,
              offsetY: 0,              
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 250,
            itemMargin: 100
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="donut"
        width={300}
        className="donut_chart"
      />
    </>
  );
}
